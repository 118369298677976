<template>
  <div class="zbzd">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>低代码搭建平台</h1>
          <p>
            <a href="/">首页</a>
            <Icon class="arrow" type="ios-arrow-forward" />我们的优势
            <Icon class="arrow" type="ios-arrow-forward" />低代码搭建平台
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="12" :lg="12">
                <div class="inner-column">
                  <!-- <div class="image">
                    <a href="#"
                      ><img
                        src="https://img.learun.cn/022/home/411/sy/banner_pic_1.png"
                        alt=""
                        style="width: 78%; margin-left: 50px"
                    /></a>
                  </div> -->
                  <video
                    controls
                    loop
                    poster="https://file.qingflow.com/assets/index/v2021/section1-video-poster.png"
                    style="width: 100%; border-radius: 20px;"
                  >
                    <source
                      src="https://file.qingflow.com/home-section1-auto.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </Col>

              <Col :xl="12" :lg="12">
                <div class="inner-column">
                  <div class="sec-title">
                    <div class="title-icon">
                      <span class="icon"
                        ><img src="../../assets/icons/separater.png" alt=""
                      /></span>
                    </div>
                    <h2>低代码搭建平台</h2>
                  </div>
                  <div class="text">
                    <p>
                      校帮智搭是一个低代码、可视化的校园应用搭建平台，它可以帮助你快速高效的开发各类校园教育教学应用，它使用校园专属拖拽式组件和可视化配置快速构建多端应用，
                      它将一系列业务活动抽象为不同类型的“流程节点”，通过流程编辑器进行连接，可实现业务流程触发、流转、审批、消息推送等操作，免去了代码编写工作，让使用者能够完全专注于校园业务场景，
                      为学校提供一个智慧灵动的校园应用搭建工具。
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row>
          <Col
            :xl="8"
            :lg="8"
            :md="12"
            :sm="24"
            v-for="(item, index) in feature"
            :key="index"
          >
            <div class="feature-block-two">
              <div class="inner-box">
                <div class="icon-box">
                  <img :src="item.numberPic" alt="" />
                </div>
                <h3>{{ item.title }}</h3>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
          </Col>
        </Row>

        <div class="sec-title">
          <div class="title-icon">
            <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
          </div>
          <h2>图集</h2>
        </div>
        <Carousel v-model="value" loop autoplay dots="none" arrow="always" autoplay-speed="5000">
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/ddm1.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/ddm2.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/ddm3.png" alt="" srcset="">
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
    <!-- <div class="picList">
      <div class="auto-container">
         <Carousel
          v-model="picCarousel"
          dots="none"
          arrow="always"
          loop autoplay 
        >
          <CarouselItem>
            <img class="pic" src="../../assets/background/10.png" alt="">
          </CarouselItem>
          <CarouselItem>
            <img class="pic" src="../../assets/background/11.png" alt="">
          </CarouselItem>
          <CarouselItem>
            <img class="pic" src="../../assets/background/12.png" alt="">
          </CarouselItem>
        </Carousel>
         <video controls loop autoplay poster="https://file.qingflow.com/assets/index/v2021/section1-video-poster.png" style="width:100%;border-radius:20px">
          <source
            src="https://file.qingflow.com/home-section1-auto.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div> -->
  </div>
</template>
<script>
import xbzd from "./xbzd.js";
export default xbzd;
</script>

<style lang="less" scoped>
@import "./xbzd.less";
</style>