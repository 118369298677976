export default {
    data() {
        return {
            picCarousel: 0,
            feature: [{
                title: '满足校园信息化应用中的绝大部分办公应用场景',
                numberPic: require("../../assets/icons/num1.png"),
                text: '通过低代码搭建平台，经过简单培训的教师即可自行搭建办公管理应用，学校常规使用的公文流转、请假管理、报修管理、场馆申请、物品申购、各类信息上报等都可以根据学校的管理流程和个性化快速搭建生成。'
            }, {
                title: '控件拖拽构建，所见即所得',
                numberPic: require("../../assets/icons/num2.png"),
                text: '系统内置完整的控件系统，包括文本框、单选多选、下拉、表格、地图、图表等控件，支持通过图形化界面拖拽组件，提供所见即所得的可视化应用构建工具，支持自由布局、插件拖拽、属性配置、实时预览。'
            }, {
                title: '校园专属组件，术业有专攻',
                numberPic: require("../../assets/icons/num3.png"),
                text: '系统支持教师、学生、场馆、年级班级、部门、教学周、学科等校园专用场景组件，同时包含常用文本、单多选、时间选择器、图片、附件等组件，各组件可以动态设置各类参数。'
            }, {
                title: '自定义工作流，轻松定义复杂流程',
                numberPic: require("../../assets/icons/num4.png"),
                text: '系统自定义工作流可以自定义流程分支，任意环节均可设置审核人、办理人、抄送人及子分支，审核权限支持或签或者会签，人员权限设置支持学校组织架构勾选以及班主任、任课教师等组权限，另外，系统支持不同校区管理权限分配及教师定向权限。'
            }, {
                title: '生成多端应用，实现即时推送',
                numberPic: require("../../assets/icons/num5.png"),
                text: '系统具备多终端适应能力，可实现多终端展示，如PC端、广告屏、移动APP、钉钉、微信公众号及企业微信等，同时各审批、交办及抄送业务流程均可自动生成消息推送功能。'
            }],
        }
    },
    created() {

    },
}